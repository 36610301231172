/** @jsx jsx */
import { jsx } from "theme-ui";
import { useRef, useState, useEffect } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Header from "@components/Header";
import { useWindowWidth } from "@react-hook/window-size";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css"; //this will auto show and hide the link on focus
import { Helmet } from "react-helmet";
import theme, { rem } from "@src/theme";

import Footer from "@components/Footer";
import { splitTags } from "@src/helpers/utils";
import "../../fonts/fonts.css";
import { GlobalStyle } from "../../";
import "../../fonts/kepler.css";

interface ComponentProps {
  children: React.ReactNode;
  fixedSidebar?: Boolean;
  location: any;
}

const Layout = ({
  children,
  fixedSidebar = false,
  location,
}: ComponentProps) => {
  const [menuOpened, toggleMenu] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [tablet, setTablet] = useState(false);
  const [showIEBanner, setShowIEBanner] = useState(true);
  const [menuType, setMenuType] = useState("");
  const sideBarRef = useRef();
  const windowWidth = useWindowWidth();
  const data = useStaticQuery(graphql`
    {
      craft {
        categories {
          id
          slug
          title
          ... on Craft_tags_Category {
            tagType
          }
        }
      }
    }
  `);

  const topics = splitTags("topic", data.craft.categories).filter(
    ({ slug }) => slug !== "contributors"
  );
  const contentTypes = splitTags("contentType", data.craft.categories);
  const regions = splitTags("region", data.craft.categories);

  useEffect(() => {
    const upperMobileBp = theme.breakpoints[0].replace("px", "");
    const upperTabletBp = theme.breakpoints[1].replace("px", "");
    if (menuOpened) {
      if (windowWidth) {
        if (windowWidth < parseInt(upperMobileBp)) {
          setMobile(true);
        } else {
          setMobile(false);
        }
      }
    }

    if (
      windowWidth > parseInt(upperMobileBp) &&
      windowWidth < parseInt(upperTabletBp)
    ) {
      setTablet(true);
    } else {
      setTablet(false);
    }
  }, [windowWidth]);

  const toggle = (menuType?: string) => {
    if (!menuOpened) {
      if (menuType) {
        setMenuType(menuType);
      }
      disableBodyScroll(sideBarRef.current);
      document.getElementsByTagName("html")[0].className = "drawer-open";
    } else {
      enableBodyScroll(sideBarRef.current);
      setMenuType("");
      document.getElementsByTagName("html")[0].className = "";
    }

    toggleMenu(!menuOpened);
  };

  const mobileToggle = () => {
    if (mobile) {
      setMobile(false);
    } else {
      setMobile(true);
    }
    toggle();
  };

  const determineMargin = (mobile, tablet, opened) => {
    if (mobile && opened) {
      return {
        marginLeft: "-312px",
        marginRight: "0",
      };
    } else if (!mobile && opened) {
      return {
        marginLeft: "312px",
        marginRight: "0",
      };
    }

    return tablet
      ? {
          marginLeft: "auto",
          marginRight: "auto",
        }
      : {
          marginLeft: "auto",
          marginRight: "auto",
        };
  };

  const closeMenu = () => {
    if (menuOpened) {
      toggleMenu(false);
      setMobile(false);
      enableBodyScroll(sideBarRef.current);
    }
  };

  const onTagLinkClick = (tagSlug) => {
    if (
      (location && location.pathname === `/tags/${tagSlug}/`) ||
      location.pathname === `/tags/${tagSlug}`
    ) {
      closeMenu();
    }
    enableBodyScroll(sideBarRef.current);
  };

  const Topics = () => (
    <React.Fragment>
      <p
        sx={{
          variant: "typography.bodyLarge",
          color: "light",
          mb: rem(18),
        }}
      >
        Topics
      </p>
      {topics.map(({ slug, title }) => (
        <div
          sx={{
            mb: rem(18),
          }}
        >
          <Link
            to={`/tags/${slug}`}
            sx={{
              fontFamily: "heading",
              fontSize: rem(32),
              color: "morning",
              textDecoration: "none",
              lineHeight: 1.1,
              letterSpacing: "-1px",
            }}
            onClick={() => {
              onTagLinkClick(slug);
            }}
          >
            {title}
          </Link>
        </div>
      ))}
      <p
        sx={{
          variant: "typography.bodyLarge",
          color: "light",
          mb: rem(18),
        }}
      >
        Types
      </p>
      {contentTypes.map(({ slug, title }) => (
        <div
          sx={{
            mb: rem(18),
          }}
        >
          <Link
            to={`/tags/${slug}`}
            sx={{
              fontFamily: "heading",
              fontSize: rem(32),
              color: "morning",
              textDecoration: "none",
              lineHeight: 1.1,
              letterSpacing: "-1px",
            }}
            onClick={() => {
              onTagLinkClick(slug);
            }}
          >
            {title}
          </Link>
        </div>
      ))}
    </React.Fragment>
  );

  const Regions = () => (
    <React.Fragment>
      <p
        sx={{
          variant: "typography.bodyLarge",
          color: "light",
          mb: rem(18),
        }}
      >
        Regions
      </p>
      {regions.map(({ slug, title }) => (
        <div
          sx={{
            mb: rem(18),
          }}
        >
          <Link
            to={`/tags/${slug}`}
            sx={{
              fontFamily: "heading",
              fontSize: rem(32),
              color: "morning",
              textDecoration: "none",
              lineHeight: 1.1,
              letterSpacing: "-1px",
            }}
            onClick={() => {
              onTagLinkClick(slug);
            }}
          >
            {title}
          </Link>
        </div>
      ))}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <SkipNavLink />
      <GlobalStyle />
      <Helmet>
        <script>
          {`(function(d) {
var el, fjs = d.getElementsByTagName('script')[0];
el = d.createElement('script');
el.id = "js-pp-banner";
el.src =
"//dashboard.presspatron.com/dev/banner?b=wCUc2iNiCxZJTJjECwEcYDwN";
fjs.parentNode.insertBefore(el, fjs);
}(document));`}
        </script>
      </Helmet>
      {showIEBanner && (
        <div
          sx={{
            bg: "#F9D8A1",
            px: ["16px", null, "2vw", null, "3vw", "4vw"],
            width: "100%",
            maxWidth: ["100%", rem(720), "1580px"],
            display: "none",
            "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
              display: "block",
            },
          }}
        >
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p
              sx={{
                fontSize: rem(20),
              }}
            >
              Improve your Gippslandia browsing experience by using{" "}
              <a
                href="https://www.google.com/chrome/"
                target="_blank"
                sx={{
                  color: "inherit",
                }}
              >
                Chrome
              </a>{" "}
              or{" "}
              <a
                href="https://support.apple.com/en_AU/downloads/safari"
                target="_blank"
                sx={{
                  color: "inherit",
                }}
              >
                Safari
              </a>
              .
            </p>
            <button
              sx={{
                fontFamily: "heading",
              }}
              onClick={() => setShowIEBanner(false)}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                sx={{
                  mr: "5px",
                }}
              >
                <line
                  x1="0.292893"
                  y1="11.2929"
                  x2="10.2929"
                  y2="1.29289"
                  stroke="currentColor"
                  stroke-width="2"
                />
                <line
                  x1="0.707107"
                  y1="1.29289"
                  x2="10.7071"
                  y2="11.2929"
                  stroke="currentColor"
                  stroke-width="2"
                />
              </svg>
              Close
            </button>
          </div>
        </div>
      )}

      <div
        sx={{
          overflowX: "hidden",
          background: menuOpened ? "#FAF2F6" : "#f9f9f9",
        }}
      >
        <div
          sx={{
            bg: "black",
            color: "#f9f9f9",
            textAlign: "center",
            py: rem(20),
          }}
        >
          <p
            sx={{
              mt: 0,
              mb: 0,
              variant: "typography.bodyMedium",
            }}
          >
            Contribute to <i>Gippslandia</i> and support positive local
            storytelling. —{" "}
            <a
              href="https://dashboard.presspatron.com/donations/new?frame=1&t=wCUc2iNiCxZJTJjECwEcYDwN"
              sx={{
                color: "inherit",
                textDecoration: "underline",
              }}
            >
              donate here
            </a>
          </p>
        </div>
        <div
          sx={{
            display: ["none", "block"],
          }}
        >
          <div
            tabIndex={menuOpened ? 1 : -1}
            aria-hidden={menuOpened ? "false" : "true"}
            sx={{
              bg: "char",
              color: "morning",
              position: "fixed",
              height: "100%",
              minHeight: "-webkit-fill-available",
              maxHeight: "100%",
              overflowX: "hidden",
              width: "312px",
              transform: menuOpened ? "translateX(0)" : "translateX(-312px)",
              transition: "transform 250ms ease",
              zIndex: 9999,
              "a:hover": {
                bg: "rgba(0, 0, 0, 0.40)",
              },
            }}
          >
            <div
              ref={sideBarRef}
              sx={{
                width: "85%",
                mx: "auto",
              }}
            >
              <button
                sx={{
                  fontFamily: "heading",
                  color: "morning",
                  mt: 4,
                  fontSize: rem(16),
                }}
                onClick={() => toggle()}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{
                    mr: "5px",
                  }}
                >
                  <line
                    x1="0.292893"
                    y1="11.2929"
                    x2="10.2929"
                    y2="1.29289"
                    stroke="#FBE8F1"
                    stroke-width="2"
                  />
                  <line
                    x1="0.707107"
                    y1="1.29289"
                    x2="10.7071"
                    y2="11.2929"
                    stroke="#FBE8F1"
                    stroke-width="2"
                  />
                </svg>
                Close
              </button>
              {menuType === "topics" ? <Topics /> : <Regions />}
            </div>
          </div>
        </div>

        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            py: "20px",
            px: ["16px", null, "2vw", null, "3vw", "4vw"],
            width: "100%",
            maxWidth: ["100%", rem(720), "1580px"],
            backgroundColor: "#F9F9F9",
            ...determineMargin(mobile, tablet, menuOpened),
            transition: "all 250ms ease",
            "&:after": menuOpened
              ? {
                  content: "''",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  bg: "morning",
                  opacity: 0.4,
                  cursor: "pointer",
                }
              : {},
          }}
          onClick={closeMenu}
        >
          <Header
            onMobileMenuToggle={mobileToggle}
            onMenuToggle={toggle}
            fixedSidebar={fixedSidebar}
          />
          {!fixedSidebar && (
            <hr
              sx={{
                display: ["none", null, "block"],
                bg: "coal",
                height: "1px",
                border: "none",
                width: "100%",
                mt: "20px",
                mb: 0,
                "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
                  display: "none",
                },
              }}
            />
          )}

          <div sx={{ mb: rem(24) }}></div>
          <SkipNavContent />
          <main
            id="content"
            sx={{
              width: "100%",
              flex: "1 1 auto",
              mx: "auto",
            }}
          >
            <div
              sx={{
                width: "100%",
              }}
            >
              {children}
            </div>
          </main>

          <Footer />
        </div>
        <div
          sx={{
            display: ["block", "none"],
          }}
        >
          <div
            tabIndex={menuOpened ? 1 : -1}
            aria-hidden={menuOpened ? "false" : "true"}
            sx={{
              bg: "char",
              color: "morning",
              position: "fixed",
              height: "100%",
              minHeight: "-webkit-fill-available",
              maxHeight: "100%",
              overflowX: "hidden",
              width: "312px",
              transform: menuOpened ? "translateX(0)" : "translateX(312px)",
              transition: "all 250ms ease",
              zIndex: 9999,
              right: 0,
              top: 0,
              "a:hover": {
                bg: "rgba(0, 0, 0, 0.40)",
              },
            }}
          >
            <div
              ref={sideBarRef}
              sx={{
                width: "85%",
                mx: "auto",
                overflowY: "auto",
                height: "calc(100% + 1px)", // fixes an iOS Safari bug where dynamically rendered content beyond the viewport doesn't scroll
              }}
            >
              <button
                sx={{
                  fontFamily: "heading",
                  color: "morning",
                  mt: 4,
                  mb: rem(50),
                  fontSize: rem(16),
                }}
                onClick={mobileToggle}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{
                    mr: "5px",
                  }}
                >
                  <line
                    x1="0.292893"
                    y1="11.2929"
                    x2="10.2929"
                    y2="1.29289"
                    stroke="#FBE8F1"
                    stroke-width="2"
                  />
                  <line
                    x1="0.707107"
                    y1="1.29289"
                    x2="10.7071"
                    y2="11.2929"
                    stroke="#FBE8F1"
                    stroke-width="2"
                  />
                </svg>
                Close
              </button>

              <hr
                sx={{
                  bg: "morning",
                  height: "1px",
                  border: "none",
                }}
              />
              <Topics />
              <Regions />
              <hr
                sx={{
                  bg: "morning",
                  height: "1px",
                  border: "none",
                  mt: rem(40),
                  mb: rem(20),
                }}
              />
              {LINKS.map(({ slug, label, external }) =>
                external ? (
                  <div
                    sx={{
                      mb: rem(18),
                    }}
                  >
                    <a
                      href={slug}
                      target="_blank"
                      sx={{
                        fontFamily: "heading",
                        fontSize: rem(32),
                        color: "morning",
                        textDecoration: "none",
                        display: "block",
                        lineHeight: 1.1,
                      }}
                    >
                      {label}
                    </a>
                  </div>
                ) : (
                  <div
                    sx={{
                      mb: rem(18),
                    }}
                  >
                    <Link
                      to={`/${slug}`}
                      sx={{
                        fontFamily: "heading",
                        fontSize: rem(32),
                        color: "morning",
                        textDecoration: "none",
                        display: "block",
                        lineHeight: 1.1,
                        letterSpacing: "-1px",
                      }}
                      onClick={() => {
                        onTagLinkClick(label.toLowerCase());
                      }}
                    >
                      {label}
                    </Link>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;

const LINKS = [
  { slug: "/tags/contributors", label: "Contributors" },
  { slug: "https://gippslandia.myshopify.com", label: "Shop", external: true },
  {
    slug: "https://gippslandia.myshopify.com/collections/gippslandia-1",
    label: "Become a member",
    external: true,
  },
  {
    slug: "/about",
    label: "About",
  },
  {
    slug: "/partners",
    label: "Partners",
  },
  {
    slug: "/stockists",
    label: "Stockists",
  },
];
