/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "../../theme";

const ButtonLink = (props) => (

  <a
    sx={{
      color: "inherit",
      textDecoration: "none",
      px: rem(17),
      pt: rem(12),
      pb: rem(10),
      variant: "buttons.primary",
      border: "none",
      fontFamily: "heading",
      fontSize: [rem(16)],
      textTransform: "uppercase",
      cursor: "pointer",
      letterSpacing: "-0.25px",
      "&:hover": {
        bg: "rgba(0,0,0,1);",
        transform: "translateY(-1px)",
      },
    }}
    {...props}
  >
    Support
  </a>

);

export default ButtonLink;
