export const cellAfterLine = {
  position: "relative",
  "&:after": {
    content: "''",
    display: "block",
    bg: "black",
    width: "1px",
    height: "-webkit-fill-available",
    maxHeight: "100%",
    position: "absolute",
    right: ["-8px", "-9px", "-12px", null, "-24px"],
    top: 0,
  },
};

export const cellBeforeLine = {
  position: "relative",
  "&:before": {
    content: "''",
    display: "block",
    bg: "black",
    width: "1px",
    height: "-webkit-fill-available",
    maxHeight: "100%",
    position: "absolute",
    left: ["-8px", "-9px", "-12px", null, "-24px"],
    top: 0,
  },
};
