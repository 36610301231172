import { rem } from "@src/theme";

export default {
  container: {
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  descriptionBanner: {
    mt: rem(20),
    display: ["none", "block", "none"],
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      display: "block",
    },
  },
};
