import { rem } from "@src/theme";

export default {
  footerContainer: {
    bg: "morning",
    py: rem(75),
    mb: [0, "18px", "24px", null, "48px"],
    mx: ["-16px", "-18px", 0],
    px: ["16px", null, 0],

    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      px: ["16px", null, 0],
    },
  },
  seperator: {
    bg: "coal",
    height: "1px",
    border: "none",
    display: ["block", null, "none"],
    my: rem(40),
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      display: "block",
    },
  },
  browseContainer: {
    bg: "char",
    pt: rem(56),
    pb: rem(80),
    mx: ["-16px", "-18px", 0],
    px: ["16px", null, 0],
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      px: "16px",
    },
    "a:hover": {
      bg: "rgba(0, 0, 0, 0.40)",
    },
  },
};
