/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { useState } from "react";
import axios from "axios";

import { Grid, Cell } from "@components/primitives/Grid";
import { rem } from "@src/theme";
import Button from "@src/components/Button";
import ButtonLink from "@src/components/ButtonLink";
import { Link, useStaticQuery, graphql, navigate } from "gatsby";
import ReadMore from "@components/ReadMore";
import { Form, FormikProps, Formik } from "formik";
import Input from "@src/components/Input";
import * as Yup from "yup";
import { cellAfterLine as verticalLineStyle } from "@src/global-styles/verticalLine";
import { splitTags } from "@src/helpers/utils";
import styles from "./styles";
const subscribeValidation = Yup.object().shape({
  email: Yup.string()
    .email()
    .required("We require this"),
  firstName: Yup.string().required("We require this"),
});
const initialValuesForm = {
  email: "",
  firstName: "",
};

interface subscribeFormValues {
  email: string;
  firstName: string;
}

const ABOUT_LINKS_1 = [
  { slug: "/editorial-guidelines", label: "Editorial guidelines" },
  { slug: "/complaint-policy", label: "Complaint policy" },
  { slug: "/privacy-policy", label: "Privacy policy" },
];
const ABOUT_LINKS_2 = [
  { slug: "/contact", label: "Contact us" },
  { slug: "/stockists", label: "Stockists" },
  {
    slug: "https://www.facebook.com/Gippslandia/",
    label: "Facebook",
    external: true,
  },
  {
    slug: "https://www.instagram.com/gippslandia/?hl=en",
    label: "Instagram",
    external: true,
  },
  {
    slug: "https://au.linkedin.com/company/gippslandia?trk=ppro_cprof",
    label: "Linkedin",
    external: true,
  },
];
const cellAfterLine = {
  ...verticalLineStyle,
  "&:after": {
    ...verticalLineStyle["&:after"],
    height: "64px",
  },
};

const Footer = () => {
  const [signupResult, setSignupResult] = useState(null);
  const data = useStaticQuery(graphql`
    {
      craft {
        logos: globalSet {
          ... on Craft_partnerLogos_GlobalSet {
            logos {
              ... on Craft_logos_BlockType {
                image {
                  url
                }
                partnerUrl
              }
            }
          }
        }

        categories: categories {
          id
          slug
          title
          ... on Craft_tags_Category {
            tagType
          }
        }
      }
    }
  `);

  const topics = splitTags("topic", data.craft.categories);

  const regions = splitTags("region", data.craft.categories);
  const { logos } = data.craft.logos;

  const onNewsletterSubmit = async ({ firstName, email }) => {
    try {
      await axios.post(process.env.GATSBY_NEWSLETTER_SIGNUP_ENDPOINT, {
        firstname: firstName,
        email,
      });
      setSignupResult("You've been added to our newsletter!");
    } catch (err) {
      console.log(err);
      setSignupResult(
        "There was an issue adding you to our newsletter, please try again later or get in touch with us on our contact page."
      );
    }
  };
  return (
    <footer
      sx={{
        overflow: "none",
      }}
    >
      {" "}
      <Cell
        styles={{
          height: rem(113),
        }}
      />
      <section sx={styles.footerContainer}>
        <Grid>
          <Cell gridColumn={["1 / -1", null, "2 / span 6"]}>
            <Styled.h3
              sx={{
                mt: 0,
                mb: rem(0),
              }}
            >
              Support Gippslandia
            </Styled.h3>
            <p
              sx={{
                variant: "typography.bodyMedium",
                mt: rem(10),
              }}
            >
              Support from our readers is what keeps the lights on and the
              printing presses running.
            </p>
            <ButtonLink href="https://dashboard.presspatron.com/donations/new?frame=1&t=wCUc2iNiCxZJTJjECwEcYDwN">
              Support
            </ButtonLink>
          </Cell>
          <Cell
            gridColumn={["1 / -1", null, "8 / span 1"]}
            styles={{
              ...cellAfterLine,
              "&:after": {
                ...cellAfterLine["&:after"],
                height: "100%",
                display: ["none", null, "block"],
              },
            }}
          >
            {" "}
            <hr sx={styles.seperator} />
          </Cell>

          <Cell gridColumn={["1 / -1", null, "10 / span 6"]}>
            {/* <Styled.h3
              sx={{
                mt: 0,
                mb: rem(0),
              }}
            >
              Get Gippslandia in your inbox
            </Styled.h3>
            <p
              sx={{
                variant: "typography.bodyMedium",
                mt: rem(10),
              }}
            >
              Get fresh updates from your favourite periodical.... periodically.
            </p>
            <Formik
              initialValues={initialValuesForm}
              onSubmit={(values, actions) => {
                onNewsletterSubmit(values);
                // @ts-ignore
                actions.resetForm(initialValues);
              }}
              enableReinitialize
              validationSchema={subscribeValidation}
            >
              {(props: FormikProps<subscribeFormValues>) => (
                <Form>
                  <div
                    sx={{
                      display: "flex",
                    }}
                  >
                    <div
                      sx={{
                        flex: 1,
                        mr: rem(10),
                      }}
                    >
                      <Input
                        name="firstName"
                        type="text"
                        placeholder="First name"
                      />
                    </div>
                    <div
                      sx={{
                        flex: 1.5,
                      }}
                    >
                      <Input
                        name="email"
                        type="email"
                        placeholder="Email address"
                      />
                    </div>
                  </div>
                  {signupResult && <p>{signupResult}</p>}

                  <div
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Button>Subscribe</Button>
                  </div>
                </Form>
              )}
            </Formik> */}
          </Cell>
        </Grid>
      </section>
      <section sx={styles.browseContainer}>
        <Grid>
          <Cell gridColumn={["1 / -1", null, "2 / span 7"]}>
            <p
              sx={{
                fontSize: rem(32),
                mb: rem(5),
                color: "light",
                mt: [0, null, "20px"],
              }}
            >
              Browse topics
            </p>
            {topics.map(({ slug, title }) => (
              <div>
                <Link
                  to={`/tags/${slug}`}
                  sx={{
                    fontSize: [rem(42), "3.95em!important"],
                    fontFamily: "heading",
                    color: "morning",
                  }}
                >
                  {title}
                </Link>
              </div>
            ))}
          </Cell>
          <Cell gridColumn={["1 / -1", null, "10 / span 6"]}>
            <p
              sx={{
                fontSize: rem(32),
                mb: rem(8),
                color: "light",
                mt: ["1.5em", null, "20px"],
              }}
            >
              Explore regions
            </p>
            {regions.map(({ slug, title }) => (
              <div
                sx={{
                  mb: rem(12),
                }}
              >
                <Link
                  to={`/tags/${slug}`}
                  sx={{
                    fontSize: [rem(24)],
                    fontFamily: "heading",
                    color: "smoke",
                  }}
                >
                  {title}
                </Link>
              </div>
            ))}
          </Cell>
        </Grid>
      </section>
      <Grid>
        <Cell gridColumn={["1 / -1", null, "2 / span 14"]}>
          <section
            sx={{
              py: rem(96),
              borderBottom: "1px solid black",
            }}
          >
            <Styled.h3 sx={{ mb: rem(10), mt: 0 }}>Partners</Styled.h3>
            <p
              sx={{
                fontSize: ["1.91em", "32px"],
                lineHeight: 1.2,
                mb: rem(34),
                mt: 0,
              }}
            >
              Gippslandia is made possible thanks to our supporting partners.
              They are businesses that believe in the value of sharing
              optimistic tales from our great region. We encourage you to
              support them in return, as without them, Gippslandia wouldn’t
              exist.
            </p>
            {logos.length && (
              <div
                sx={{
                  maxWidth: ["100%", null, rem(806)],
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Grid
                  gridTemplateColumns={["repeat(16, 1fr)"]}
                  styles={{
                    width: "100%",
                  }}
                >
                  {logos.map(({ image, partnerUrl }) => (
                    <Cell gridColumn={["span 8", "span 4"]}>
                      {partnerUrl ? (
                        <a
                          href={partnerUrl}
                          target="_blank"
                          sx={{
                            "&:hover": {
                              bg: "transparent",
                            },
                            "@media only screen and (max-width: 380px)": {
                              width: "100%",
                            },
                          }}
                        >
                          <img
                            src={image[0].url}
                            sx={{
                              "@media only screen and (max-width: 380px)": {
                                width: "100%",
                              },
                            }}
                          />
                        </a>
                      ) : (
                        <div sx={{}}>
                          <img
                            src={image[0].url}
                            sx={{
                              "@media only screen and (max-width: 380px)": {
                                width: "100%",
                              },
                            }}
                          />
                        </div>
                      )}
                    </Cell>
                  ))}
                </Grid>{" "}
              </div>
            )}
          </section>

          <section
            sx={{
              py: rem(96),
              borderBottom: "1px solid black",
            }}
          >
            <Styled.h3 sx={{ mb: rem(10), mt: 0 }}>About Gippslandia</Styled.h3>
            <p
              sx={{
                fontSize: ["1.91em", "32px"],
                lineHeight: 1.2,
                mb: rem(48),
                mt: 0,
              }}
            >
              Gippslandia is a community, non-profit publication. We curate an
              ever-optimistic take on regional, national and global issues, in a
              local context. Leaving you feeling like a Gippslandia local, no
              matter where you’re from. <ReadMore url="/about" />
            </p>
            <Grid gridTemplateColumns={["repeat(14, 1fr)"]}>
              <Cell gridColumn={["1 / -1", null, "1 / span 4"]}>
                <ul
                  sx={{
                    listStyle: "none",
                  }}
                >
                  {ABOUT_LINKS_1.map(({ slug, label }) => (
                    <li
                      sx={{
                        mb: rem(14),
                      }}
                    >
                      <Link
                        to={slug}
                        sx={{
                          variant: "typography.link",
                          color: "inherit",
                        }}
                      >
                        {label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Cell>
              <Cell gridColumn={["1 / -1", null, "6 / span 3"]}>
                <ul
                  sx={{
                    listStyle: "none",
                  }}
                >
                  {ABOUT_LINKS_2.map(({ slug, label, external }) => (
                    <li
                      sx={{
                        mb: rem(14),
                      }}
                    >
                      {external ? (
                        <a
                          href={slug}
                          target="_blank"
                          sx={{
                            variant: "typography.link",
                            color: "inherit",
                          }}
                        >
                          {label}
                        </a>
                      ) : (
                        <Link
                          to={slug}
                          sx={{
                            variant: "typography.link",
                            color: "inherit",
                          }}
                        >
                          {label}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </Cell>
            </Grid>
          </section>

          <p
            sx={{
              variant: "typography.headingSmall",
            }}
          >
            © 2021 Gippslandia, All rights reserved{" "}
          </p>
        </Cell>
      </Grid>
    </footer>
  );
};

export default Footer;
