export const splitTags = (type, arr) =>
  arr.filter(({ tagType }) => tagType === type);

export const trimSentence = (str, cutOff) => {
  if (str.length > cutOff) {
    //trim the string to the maximum length
    var trimmedString = str.substr(0, cutOff);

    //re-trim if we are in the middle of a word and
    trimmedString = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );
    return trimmedString + "...";
  } else {
    return str;
  }
};
