/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { rem } from "@src/theme";
import React from "react";

interface ComponentProps {
  url: string;
  label?: string;
}
const ReadMore = ({ url, label }: ComponentProps) => (
  <React.Fragment>
    {" "}
    <Link
      to={url}
      sx={{
        fontFamily: "heading",
        textDecoration: "underline",
        fontSize: rem(16),
        color: "black",
        letterSpacing: "-.25px",
        whiteSpace: "nowrap",
      }}
    >
      {label || "Read more"}
    </Link>
  </React.Fragment>
);

export default ReadMore;
